import { fabric } from "fabric";

export default class BboxCanvas {
    constructor(id, image, max_width, force_height, read_only, max_height) {


        if(!max_width) {
            max_width = document.querySelector('#' + id).parentElement.clientWidth;
        }

        this.draw_annotations = false;

        this.maxHeight = max_height;

        this.canvasWidth = Math.min(max_width, 1400);

        this.shift_pressed = false;
        this.alt_pressed = false;

        this.in_group_selection = [];

        this.originalCanvasWidth = this.canvasWidth
        document.querySelector('#' + id).setAttribute('width', this.canvasWidth + 'px')

        this.deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
        this.canvas = new fabric.Canvas(id,
            {
                uniformScaling: false,
                uniScaleKey: false,
                fireRightClick: true,  // <-- enable firing of right click events
                fireMiddleClick: true, // <-- enable firing of middle click events
                stopContextMenu: true, // <--  prevent context menu from showing
            });

        this.setPointValues();

        this.boxBorder = 1;

        this.clear();

        this.canvas.on(
            'selection:cleared', function (e) {

                let deselected = e.deselected;
                if(!deselected) return;

                let annotation_ids_in_group = this2.in_group_selection.map(s=>{return s.annotation_id})

                deselected.forEach(object=>{
                    if(!annotation_ids_in_group.includes(object.annotation_id)) {
                        window.dispatchEvent(new CustomEvent("unselect_annotation", {
                            detail: {
                                id: object.annotation_id,
                            }
                        }));
                    }
                })
            });

        let this2 = this;

        this.canvas.on('selection:created', function() {
            let activeObject = this2.canvas.getActiveObject()
            if(activeObject._objects) {
                activeObject.setControlsVisibility({
                    mtr: false
                });
            }
        })
        this.canvas.on('selection:updated', function() {
            let activeObject = this2.canvas.getActiveObject()
            if(activeObject._objects) {
                activeObject.setControlsVisibility({
                    mtr: false
                });
            }
        })

        this.canvas.on('object:moving', function(e) {
            if(e.target._objects) {
                e.target._objects.forEach((obj) => {
                    this2.moveObject(obj, e.target.left + e.target.width / 2, e.target.top + e.target.height / 2)
                    obj.onTheMove = true;
                })
            }
            else {
                this2.moveObject(e.target, 0, 0)
                e.target.onTheMove = true;
            }
        });

        this.canvas.on('object:scaling', function(e) {
            if(e.target._objects) {
                e.target._objects.forEach((obj) => {
                    obj.onTheMove = true;
                })
            }
            else {
                e.target.onTheMove = true;
            }
        });

        this.canvas.on('object:modified', function(e) {
            if(e.target._objects) {
                e.target._objects.forEach((obj) => {
                    this2.modifyObject(obj, e.target.left + e.target.width / 2, e.target.top + e.target.height / 2)
                    obj.onTheMove = false;
                })
            }
            else {
                this2.modifyObject(e.target, 0, 0)
                e.target.onTheMove = false;
            }
        });



        this.canvasId = id;

        let modelSize = 640
        this.modelSize = modelSize

        this.filtersInitialized = false;
        this.filterError = false;

        window.deleteIconImg = document.createElement('img');
        window.deleteIconImg.src = this.deleteIcon;

        let canvas = this.canvas;
        canvas.canvasId = id;
        canvas.readOnly = read_only;
        this.read_only = read_only;


        this.canvas.on('mouse:down', function (opt) {
            let rect = canvas.getElement().getBoundingClientRect();

            let mouseDownX = opt.e.x - rect.left;
            let mouseDownY = opt.e.y - rect.top;

            // Get the current zoom level
            let zoom = canvas.getZoom();
            let scale = canvas.scale;
            // Adjust coordinates based on zoom and viewport transformation
            let adjustedX = (mouseDownX / zoom) - (canvas.viewportTransform[4] / zoom);
            let adjustedY = (mouseDownY / zoom) - (canvas.viewportTransform[5] / zoom);
            adjustedX = adjustedX / scale;
            adjustedY = adjustedY / scale;

            window.dispatchEvent(new CustomEvent(this2.canvasId + "canvas_mousedown", {
                detail: {
                    x: mouseDownX,
                    y: mouseDownY,
                    scale: this.scale,
                    zoom: zoom,
                    transformX: canvas.viewportTransform[4],
                    transformY: canvas.viewportTransform[5],
                    original_x: adjustedX,
                    original_y: adjustedY
                }
            }));
        });

        if (image) {
            this.setImage(image)
        }
    }

    updateLabelsOnZoom() {

        let this2 = this;
        this.canvas.getObjects().forEach((obj) => {
            try {
            if(obj.text && typeof obj.text !== "string") {

                obj.text.set({
                    fontSize: Math.max(5, this2.canvasWidth / 35 / this2.canvas.getZoom()),
                    top: obj.top - Math.max(5, this2.canvasWidth / 35 / this2.canvas.getZoom()) - 1,
                });
            }
            } catch(e) {
                console.log(typeof obj.text)
            }
        })
    }

    modifyObject(obj, marginLeft, marginTop) {
        let this2 = this;

        let left = obj.left + marginLeft;
        let top = obj.top + marginTop;

        obj.set({
            left: left,
            top: top
        })

        window.dispatchEvent(new CustomEvent("update_coords", {
            detail: {
                coords: this2.getBoxCoords(obj),
                id: obj.annotation_id,
            }
        }));
        if(obj.text) {
            obj.text.set({
                fontSize: Math.max(5, this.canvasWidth / 35 / this.canvas.getZoom()),
                left: left,
                top: top - 17,
            });
        }
        if(obj.inner_point) {
            obj.inner_point.set({
                left: left + obj.radius - this2.inner_point_radius,
                top: top + obj.radius - this2.inner_point_radius,
            })
        }
    }

    moveObject(obj, marginLeft, marginTop) {
        let left = obj.left + marginLeft;
        let top = obj.top + marginTop;

        if(obj.text) {
            obj.text.set({
                fontSize: Math.max(5, this.canvasWidth / 35 / this.canvas.getZoom()),
                left: left,
                top: top - 17,
            });
        }
        if(obj.inner_point) {
            obj.inner_point.set({
                left: left + obj.width / 2 - this.inner_point_radius,
                top: top + obj.width / 2 - this.inner_point_radius,
            })
        }
    }

    setPointValues() {
        let zoom = this.canvas.getZoom() / 2;
        this.point_radius = Math.max(3, this.canvasWidth / 100 / zoom);
        this.inner_point_radius = Math.max(1, this.canvasWidth / 300 / zoom);
    }

    setCanvasEvents() {
        let this2 = this;
        let canvas = this.canvas;
        if(!this.read_only) {
            canvas.on('mouse:wheel', function (opt) {
                var delta = opt.e.deltaY;
                if(delta<0) {
                  delta = -100
                }
                else {
                  delta = 100;
                }
                let zoom = canvas.getZoom();
                zoom *= 0.999 ** delta;

                if (zoom > 20) zoom = 20;
                if (zoom < 1) zoom = 1;

                window.dispatchEvent(new CustomEvent(this2.canvasId + "_zooming", {
                        detail: {
                            zoom: zoom,
                        }
                    }));

                if(zoom === 1) {
                    this2.resetZoom();
                }
                else {
                    canvas.zoomToPoint({x: opt.e.offsetX, y: opt.e.offsetY}, zoom);
                    opt.e.preventDefault();
                    opt.e.stopPropagation();
                    this2.requestRenderAll();
                    this2.updateLabelsOnZoom();
                }
            });
        }
    }

    requestRenderAll() {
        let objects = this.canvas.getObjects();
        objects.sort((a, b) => (a.width * a.height < b.width * b.height) ? 1 : -1);

        this.setPointValues();

        for(let i=0; i<objects.length; i++) {
            if (objects[i].annotation) {
                this.canvas.bringToFront(objects[i])
            }
            if (objects[i].is_inner_point) {
                objects[i].set('radius', this.inner_point_radius)
                objects[i].set('left', objects[i].x1 - this.inner_point_radius)
                objects[i].set('top', objects[i].y1 - this.inner_point_radius)
            }
            if (objects[i].is_point) {
                objects[i].set('radius', this.point_radius)
                objects[i].set('left', objects[i].x1 - this.point_radius)
                objects[i].set('top', objects[i].y1 - this.point_radius)
            }
        }
        this.canvas.requestRenderAll();
    }

    setImage(image) {
        this.canvasWidth = this.originalCanvasWidth

        let naturalWidth = image.width;
        let naturalHeight = image.height;

        let stretchFactor = 1
        if(naturalWidth / naturalHeight > 8 ) {
            stretchFactor = 6;
        }

        let scaleX = 0
        let scaleY = 0
        let scaledHeight = 0
        let scaledWidth = 0

        // Default (landscape mode)
        scaledWidth = this.canvasWidth
        scaleX = scaledWidth / naturalWidth
        scaledHeight = scaleX * naturalHeight
        scaleY = scaledHeight / naturalHeight
        this.scale = scaleX

        console.log('this.scale', this.scale);

        let maxHeight = window.innerHeight * 0.7;
        if(this.maxHeight) {
            maxHeight = this.maxHeight;
        }

        // Portrait mode
        if(scaledHeight > maxHeight || naturalHeight > naturalWidth) {
            scaledHeight = maxHeight;
            scaleY = scaledHeight / naturalHeight
            scaledWidth = scaleY * naturalWidth
            scaleX = scaledWidth / naturalWidth
            this.scale = scaleY
        }

        this.canvasWidth = scaledWidth;
        this.canvasHeight = scaledHeight;
        this.bboxScaleX = scaledWidth / this.modelSize;
        this.bboxScaleY = this.bboxScaleX * stretchFactor;

        document.querySelector('#' + this.canvasId).setAttribute('height', scaledHeight * stretchFactor + 'px')
        document.querySelector('#' + this.canvasId).setAttribute('width', scaledWidth + 'px')

        let canvas = this.canvas;
        this.canvas.setHeight(scaledHeight * stretchFactor)
        this.canvas.setWidth(scaledWidth)
        this.canvas.scale = this.scale;

        image.set('scaleX', scaleX).set('scaleY', scaleY * stretchFactor).set('selectable', false);

        let this2 = this;

        image.on('mousedown', function (opt) {

            if(opt.button === 3 || this2.alt_pressed) {
                let mouseDownX = opt.e.offsetX / this2.canvasWidth;
                let mouseDownY = opt.e.offsetY / this2.canvasHeight;

                window.dispatchEvent(new CustomEvent(this2.canvasId + "right_mouse_pressed", {
                    detail: {
                        x: mouseDownX,
                        y: mouseDownY
                    }
                }));
            }

            var evt = opt.e;

            if(!canvas.readOnly) {
                canvas.isDragging = true;
            }
            canvas.selection = false;
            canvas.lastPosX = evt.clientX;
            canvas.lastPosY = evt.clientY;
            window.dispatchEvent(new CustomEvent(this2.canvasId + "canvas_selected", {
                detail: {
                    id: this2.canvasId,
                }
            }));

        });
        image.on('mousemove', function (opt) {
            if (canvas.isDragging) {
                var e = opt.e;
                var vpt = canvas.viewportTransform;
                vpt[4] += e.clientX - canvas.lastPosX;
                vpt[5] += e.clientY - canvas.lastPosY;
                this2.requestRenderAll();
                canvas.lastPosX = e.clientX;
                canvas.lastPosY = e.clientY;

                window.dispatchEvent(new CustomEvent(this2.canvasId + "_dragging", {
                    detail: {
                        view_port_transform: canvas.viewportTransform,
                    }
                }));

            }
        });
        image.on('mouseup', function () {
            // on mouse up we want to recalculate new interaction
            // for all objects, so we call setViewportTransform
            canvas.setViewportTransform(canvas.viewportTransform);
            canvas.isDragging = false;
            canvas.selection = true;
        });

        image.on('mouseout', function() {
          canvas.isDragging = false;
        });

        this.addImageWithFilters(image, naturalWidth);
        this.setCanvasEvents();

        this.draw_annotations = true;
    }

    setModelPadding(naturalHeight, naturalWidth) {
        let r = Math.min(this.canvasWidth / naturalHeight, this.canvasWidth / naturalWidth)
        let unpad_w = Math.round(naturalWidth * r)
        let unpad_h = Math.round(naturalHeight * r)
        let dw = this.canvasWidth - unpad_w
        let dh = this.canvasWidth - unpad_h
        dw = (dw % 32) / 2
        dh = (dh % 32) / 2
        this.dh = dh
        this.dw = dw
    }

    setImageById(imageId) {

        let imgElement = document.getElementById(imageId);
        let naturalWidth = imgElement.naturalWidth;

        let imgInstance = new fabric.Image(imgElement, {
            left: 0,
            top: 0,
            opacity: 1,
            selectable: false,
        });

        this.addImageWithFilters(imgInstance, naturalWidth)
    }

    initFilters(imgInstance) {
        this.filtersInitialized = true;

        let f = fabric.Image.filters;

        imgInstance.filters.push(new f.Brightness({brightness: 0.01}));
        imgInstance.filters.push(new f.Contrast({contrast: 0.01}));
        imgInstance.applyFilters();
    }

    updateContrast(value) {
        let imgInstance = this.imgInstance;
        let naturalWidth = this.naturalWidth
        if (this.filterError) {
            return;
        }
        try {
            if (!this.filtersInitialized) {
                this.initFilters(imgInstance, naturalWidth)
            }
            imgInstance.filters[1]['contrast'] = parseFloat(value);
            imgInstance.applyFilters();
        } catch (error) {
            console.log('filterError', error);
            this.filterError = true;
            imgInstance.filters = [];
            imgInstance.applyFilters();
        }
        this.requestRenderAll();
    }

    updateBrightness(value) {
        let imgInstance = this.imgInstance;
        let naturalWidth = this.naturalWidth

        if (this.filterError) {
            return;
        }
        try {
            if (!this.filtersInitialized) {
                this.initFilters(imgInstance, naturalWidth)
            }
            imgInstance.filters[0]['brightness'] = parseFloat(value);
            imgInstance.applyFilters();
        } catch (error) {
            console.log('filterError', error);
            this.filterError = true;
            imgInstance.filters = [];
            imgInstance.applyFilters();
        }
        this.requestRenderAll();
    }

    addImageWithFilters(imgInstance, naturalWidth) {

        this.canvas.add(imgInstance);
        this.imgInstance = imgInstance;
        this.naturalWidth = naturalWidth;
    }

    deleteAnnotation(annotation) {
        let objects = this.canvas.getObjects();

        for (let i = 1; i < objects.length; i++) {
            let bbox = objects[i];
            if (bbox.annotation_id === annotation.id) {
                this.deleteObject(false, bbox);
            }
        }
    }

    hideText() {
        let objects = this.canvas.getObjects();

        for (let i = 1; i < objects.length; i++) {
            let bbox = objects[i];

            if(bbox.is_point) {
                bbox.opacity = 0.3;
                bbox.text.opacity = 0.0;
            }
            else if(!bbox.text_box && bbox.annotation && bbox.annotation.selected) {
                bbox.opacity = 0.9;
                if (bbox.text) {
                    bbox.text.opacity = 0.9;
                }
            }
            else {
                bbox.opacity = 0.4;
                if(!bbox.text_box && bbox.text) {
                    bbox.opacity = 0.4;
                    bbox.text.opacity = 0.0
                }
            }
        }

        // this.requestRenderAll();
    }

    showText() {
        let objects = this.canvas.getObjects();

        for (let i = 1; i < objects.length; i++) {

            let bbox = objects[i];
            if(bbox.is_point) {
                bbox.opacity = 0.6;
                bbox.text.opacity = 0.0;
            }
            else {
                bbox.opacity = 0.8;
            }
        }

        this.requestRenderAll();
    }

    selectAllAnnotations(annotation_selected) {

        let selected_annotation_ids = annotation_selected.map(a=>{return a.id});

        let objects = this.canvas.getObjects();
        let selected = objects.filter((o) => {
            return selected_annotation_ids.includes(o.annotation_id) && !o.text_box
        })

        if(selected.length > 1) {

            this.in_group_selection = selected;

            this.canvas.discardActiveObject();
            var sel = new fabric.ActiveSelection(selected, {
                canvas: this.canvas,
            });
            this.canvas.setActiveObject(sel);
            this.canvas.requestRenderAll();
        }
        else if(selected.length === 1) {
            this.canvas.setActiveObject(selected[0]);
            this.canvas.requestRenderAll();
        }

        this.in_group_selection = []
    }

    selectAnnotation(annotationId) {
        if(!annotationId) {
            return;
        }
        let objects = this.canvas.getObjects();
        for(let i=0; i<objects.length; i++) {
            if(objects[i].text_box !== true && objects[i].annotation_id === annotationId ) {
                this.canvas.setActiveObject(objects[i]);
                this.requestRenderAll();
                return;
            }
        }
    }

    getAnnotations() {
        let objects = this.canvas.getObjects();
        let annotations = [];
        objects.forEach(object=>{
            if(object.annotation) {
                annotations.push(object.annotation);
            }
        })
        return annotations;
    }

    addAnnotation(annotation, newly_added) {

        if(!this.draw_annotations) {
            return;
        }

        annotation = JSON.parse(JSON.stringify(annotation));

        let type = annotation.type;
        let color_values = annotation.color_values;
        let label = annotation.label;

        let color = new fabric.Color('rgb(' + color_values[0] + ',' + color_values[1] + ',' + color_values[2] + ')');

        let activeObject = this.canvas.getActiveObject()
        if (activeObject && activeObject.category && activeObject.category !== label  && activeObject.type === type) {
            activeObject.category = label;
            activeObject.stroke = color.toRgb();
            activeObject.text.text = label;
            activeObject.text.backgroundColor = color.toRgb();
            this.requestRenderAll();
            return;
        }

        fabric.Object.prototype.controls.deleteControl = new fabric.Control({
            x: 0.5,
            y: -0.5,
            offsetY: 16,
            cursorStyle: 'pointer',
            mouseUpHandler: this.deleteObject2,
            render: this.renderIcon,
            cornerSize: 24,
        });

        if(type === 'box') {
            this.addAnnotationBox(annotation, newly_added)
        }
        else if(type === 'point') {
            this.addAnnotationPoint(annotation, newly_added)
        }

        this.requestRenderAll();
    }

    addAnnotationBox(annotation, newly_added) {
        let coords = annotation.coords;
        let x1 = coords[0] * this.canvasWidth;
        let y1 = coords[1] * this.canvasHeight;
        let x2 = x1 + coords[2] * this.canvasWidth;
        let y2 = y1 + coords[3] * this.canvasHeight;

        if(newly_added && !annotation.skip_zoom) {
            let zoom = this.canvas.getZoom()
            x1 = x1/zoom-this.canvas.viewportTransform[4]/zoom
            y1 = y1/zoom-this.canvas.viewportTransform[5]/zoom
            x2 = x2/zoom-this.canvas.viewportTransform[4]/zoom
            y2 = y2/zoom-this.canvas.viewportTransform[5]/zoom
        }

        let label = annotation.label;
        let conf = annotation.conf;

        let color_values = annotation.color_values;
        let color = new fabric.Color('rgb(' + color_values[0] + ',' + color_values[1] + ',' + color_values[2] + ')');

        fabric.Object.prototype.controls.deleteControl = new fabric.Control({
            x: 0.5,
            y: -0.5,
            offsetY: 24,
            cursorStyle: 'pointer',
            mouseUpHandler: this.deleteObject2,
            render: this.renderIcon,
            cornerSize: 14,
        });

        let opacity = 0.8

        let box = new fabric.Rect({
            fill: false,
            left: x1 - this.boxBorder,
            top: y1 - this.boxBorder,
            height: y2 - y1,
            width: x2 - x1,
            scaleX: 1,
            scaleY: 1,
            strokeWidth: this.boxBorder,
            stroke: color.toRgb(),
            strokeUniform: true,
            lockUniScaling: false,
            opacity: opacity,
            annotation_id: annotation.id,
            annotation: annotation,
            selectable: !this.read_only,
        });

        let textLabel = label
        if(conf != 1) {
            textLabel = textLabel + ' ' + conf
        }
        var text = new fabric.IText(textLabel, {
            fontSize: Math.max(5, this.canvasWidth / 35 / this.canvas.getZoom()),
            backgroundColor: color.toRgb(),
            lockUniScaling: false,
            selectable: false,
            opacity: 0.8,
            annotation_id: annotation.id,
            text_box: true,
        });

        text.left = x1 - 1
        text.top = y1 - text.calcTextHeight() - 2

        box.text = text;

        box.set('category', label);

        box.setControlsVisibility({
            mtr: false,
        });

        this.canvas.add(text);

        this.canvas.add(box);
        let this2 = this;
        if(newly_added) {
             window.dispatchEvent(new CustomEvent("update_coords", {
                detail: {
                    coords: this2.getBoxCoords(box),
                    id: box.annotation_id,
                }
            }));
        }

        this.addEventListerenersForAnnotation(box)
    }

    addEventListerenersForAnnotation(annotation) {

        let this2 = this;
        annotation.on('mousedown', function () {
            window.dispatchEvent(new CustomEvent(this2.canvasId + "canvas_selected", {
                detail: {
                    id: this2.canvasId,
                    annotation_id: annotation.annotation_id
                }
            }));
        });

        annotation.on({
            'modified': function () {

            }
        });

        annotation.on('mouseover', function () {
            if(annotation.is_point) {
                annotation.text.opacity = 0.8
                this2.requestRenderAll();
            }
        });

        annotation.on('mouseout', function () {
            if(annotation.is_point) {
                annotation.text.opacity = 0.0
                this2.requestRenderAll();
            }
        });


        annotation.on('mousedown', function (e) {

            if(e.button === 3 || this2.alt_pressed) {
                let mouseDownX = e.e.offsetX / this2.canvasWidth;
                let mouseDownY = e.e.offsetY / this2.canvasHeight;
                window.dispatchEvent(new CustomEvent(this2.canvasId + "right_mouse_pressed", {
                    detail: {
                        x: mouseDownX,
                        y: mouseDownY
                    }
                }));
            }
            else {

                if (!e.target.annotation.selected) {
                    e.target.annotation.selected = true
                    window.dispatchEvent(new CustomEvent("select_annotation", {
                        detail: {
                            id: e.target.annotation_id,
                        }
                    }));
                    this2.deselectOnMouseUp = false;
                } else {
                    let obj = this;
                    let left = obj.left;
                    let top = obj.top;

                    this2.deselectOnMouseUp = true;
                    this2.deselectOnMouseUpPositionTop = top;
                    this2.deselectOnMouseUpPositionLeft = left;
                }
             }
        });

        annotation.on('mouseup', function (opt) {

            if(opt.button === 3) {
                this2.canvas.isDragging = false;
            }
            else {
                // on mouse up we want to recalculate new interaction
                // for all objects, so we call setViewportTransform
                this2.canvas.setViewportTransform(this2.canvas.viewportTransform);
                this2.canvas.isDragging = false;
                this2.canvas.selection = true;
            }

            if(!this2.shift_pressed && this2.deselectOnMouseUp) {
                this2.deselectOnMouseUp = false;
                let obj = this;
                let left = obj.left;
                let top = obj.top;

                if(parseInt(left) === parseInt(this2.deselectOnMouseUpPositionLeft) &&
                  parseInt(top) === parseInt(this2.deselectOnMouseUpPositionTop) ) {
                    opt.target.annotation.selected = false
                    window.dispatchEvent(new CustomEvent("unselect_annotation", {
                        detail: {
                            id: opt.target.annotation_id,
                        }
                    }));
                    opt.target.annotation.selected = false
                    this2.canvas.discardActiveObject();
                    this2.canvas.requestRenderAll();
                }
            }


        });


    }

    addAnnotationPoint(annotation, newly_added) {
        let coords = annotation.coords;

        let x1 = coords[0] * this.canvasWidth;
        let y1 = coords[1] * this.canvasHeight;

        if(newly_added && !annotation.skip_zoom) {
            let zoom = this.canvas.getZoom()
            x1 = x1/zoom-this.canvas.viewportTransform[4]/zoom
            y1 = y1/zoom-this.canvas.viewportTransform[5]/zoom
        }

        let label = annotation.label;
        let conf = annotation.conf;

        let color_values = annotation.color_values;
        let color = new fabric.Color('rgb(' + color_values[0] + ',' + color_values[1] + ',' + color_values[2] + ')');

        fabric.Object.prototype.controls.deleteControl = new fabric.Control({
            x: 0.5,
            y: -0.5,
            offsetY: 16,
            cursorStyle: 'pointer',
            mouseUpHandler: this.deleteObject2,
            render: this.renderIcon,
            cornerSize: 24,
        });


        let point = new fabric.Circle({
            radius: this.point_radius,
            fill: color.toRgb(),
            left: x1 - this.point_radius,
            top: y1 - this.point_radius,
            x1: x1,
            y1: y1,
            scaleX: 1,
            scaleY: 1,
            strokeWidth: 0,
            stroke: color.toRgb(),
            strokeUniform: true,
            lockUniScaling: false,
            opacity: 0.5,
            annotation_id: annotation.id,
            annotation: annotation,
            hasControls: true,
            selectable: !this.read_only,
            is_point: true,
        });

        let black = new fabric.Color('rgb(' + 0 + ',' + 0 + ',' + 0 + ')');
        let white = new fabric.Color('rgb(' + 255 + ',' + 255 + ',' + 255 + ')');

        let inner_point = new fabric.Circle({
            radius: this.inner_point_radius,
            fill: true,
            left: x1 - this.inner_point_radius,
            top: y1 - this.inner_point_radius,
            x1: x1,
            y1: y1,
            scaleX: 1,
            scaleY: 1,
            strokeWidth: 0,
            stroke: white.toRgb(),
            color: black.toRgb(),
            strokeUniform: true,
            lockUniScaling: false,
            opacity: 0.5,
            hasControls: false,
            selectable: false,
            is_inner_point: true,
        });

        point.set('category', label);
        point.set('inner_point', inner_point);

        let this2 = this;

        point.setControlsVisibility({
            tr: false,
            tl: false,
            bl: false,
            br: false,
            mt: false,
            mtr: false,
            mr: false,
            ml: false,
            mb: false,
        });

        let textLabel = label
        if(conf != 1) {
            textLabel = textLabel + ' ' + conf
        }
        var text = new fabric.IText(textLabel, {
            fontSize: Math.max(5, this.canvasWidth / 35 / this.canvas.getZoom()),
            backgroundColor: color.toRgb(),
            lockUniScaling: false,
            selectable: false,
            opacity: 0.0,
            annotation_id: annotation.id,
            text_box: true,
        });

        text.left = x1 - this.point_radius
        text.top = y1 - this.point_radius * 2

        point.text = text;

        this.canvas.add(inner_point);
        this.canvas.add(point);
        this.canvas.add(text);

        if(newly_added) {
             window.dispatchEvent(new CustomEvent("update_coords", {
                detail: {
                    coords: this2.getBoxCoords(point),
                    id: point.annotation_id,
                }
            }));
        }

        this.addEventListerenersForAnnotation(point)
    }

    deleteObject2(eventData, transform) {
        var target = transform.target;
        var canvas = target.canvas;

        if(target._objects) {
            target._objects.forEach(obj=>{
                if(obj.onTheMove) {
                    return
                }

                canvas.remove(obj);
                canvas.remove(obj.text);
                canvas.remove(obj.inner_point);

                window.dispatchEvent(new CustomEvent(canvas.canvasId + "delete_annotation", {
                    detail: {
                        id: obj.annotation_id,
                    }
                }));

            })

            canvas.discardActiveObject();

            canvas.requestRenderAll();
        }
        else {

            if(target.onTheMove) {
                return;
            }

            canvas.remove(target);
            canvas.remove(target.text);
            canvas.remove(target.inner_point);
            canvas.requestRenderAll();

            window.dispatchEvent(new CustomEvent(canvas.canvasId + "delete_annotation", {
                detail: {
                    id: target.annotation_id,
                }
            }));
        }
	}

	resetZoom() {

        // reset zoom
        this.canvas.zoomToPoint(new fabric.Point(this.canvas.width / 2, this.canvas.height / 2), 1.0);

        // reset movement
        let moveLeft = 0
        let moveTop = 0

        var vpt = this.canvas.viewportTransform;
        vpt[4] = moveLeft;
        vpt[5] = moveTop;

        this.updateLabelsOnZoom();

        this.canvas.requestRenderAll();
    }

	getBoxCoords(annotation) {
        let x = (annotation.left + this.boxBorder) / this.canvasWidth;
        let y = (annotation.top + this.boxBorder) / this.canvasHeight;
        if(annotation.is_point) {
            x = (annotation.left + this.point_radius) / this.canvasWidth;
            y = (annotation.top + this.point_radius)  / this.canvasHeight;
            return [x,y]
        }
        let w = (annotation.width * annotation.scaleX) / this.canvasWidth;
        let h = (annotation.height * annotation.scaleY) / this.canvasHeight;
        return [x,y,w,h]
    }

    getBboxes() {
        let objects = this.canvas.getObjects();

        let results = []
        for (let i = 1; i < objects.length; i++) {
            let bbox = objects[i];
            if (bbox.category) {
                let info = [
                    bbox.category,
                    bbox.left / this.bboxScaleX,
                    bbox.top / this.bboxScaleY,
                    (bbox.width * bbox.scaleX) / this.bboxScaleX,
                    (bbox.height * bbox.scaleY) / this.bboxScaleY];
                results.push(info)
            }
        }

        return results;
    }

    clearBboxes() {
        let objects = this.canvas.getObjects();
        for (let i = 0; i < objects.length; i++) {
            if (objects[i].category) {
                this.deleteObject(false, objects[i]);
            }
        }
        document.querySelector('#num_bboxes').html(0);
    }

    re_init() {
        this.draw_annotations = false;
        this.clear();
        let canvas = this.canvas;
        canvas.setViewportTransform([1,0,0,1,0,0]);
        this.filtersInitialized = false;
        this.filterError = false;
        this.requestRenderAll();
    }

    clear() {
        let objects = this.canvas.getObjects();
        for (let i = 0; i < objects.length; i++) {
            this.deleteObject(false, objects[i]);
        }
    }

    deleteObject(eventData, target) {
        let canvas = target.canvas;
            canvas.remove(target);
            canvas.remove(target.text);
            canvas.remove(target.inner_point);
            canvas.requestRenderAll();
        }

    renderIcon(ctx, left, top, styleOverride, fabricObject) {

        if(fabricObject.onTheMove) {
            return;
        }

        let size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(window.deleteIconImg, -size / 2, -size / 2, size, size);
        ctx.restore();
    }

    clone() {
        this.copy();
        this.paste();
        this._clipboard = false;
    }

    copy() {
        this.canvas.getActiveObject().clone(function (cloned) {
            this._clipboard = cloned;
        });
    }

    paste() {
        let this2 = this;
        this._clipboard.clone(function (clonedObj) {
            this2._clipboard = false;
            //this2.canvas.discardActiveObject();
            clonedObj.set({
                left: clonedObj.left + 10,
                top: clonedObj.top + 10,
                evented: true,
            });
            if (clonedObj.type === 'activeSelection') {
                // active selection needs a reference to the canvas.
                clonedObj.canvas = this2.canvas;
                clonedObj.forEachObject(function (obj) {
                    this2.canvas.add(obj);
                });
                // this should solve the unselectability
                clonedObj.setCoords();
            } else {
                this2.canvas.add(clonedObj);
            }
            this2._clipboard.top += 10;
            this2._clipboard.left += 10;
            this2.canvas.setActiveObject(clonedObj);
            this2.requestRenderAll();
        });
    }
}