import vSelect from 'vue-select'

<template>
  <div v-if="annotation_groups.length > 0" @keydown.enter.prevent="preventDefault">
    <autocomplete 
    :clear_on_select="true"
    :options="annotation_groups" 
    @select="select"></autocomplete>
  </div>
</template>

<script>

import LoadAnnotationGroupsMixin from "@/mixins/LoadAnnotationGroupsMixin";
import Autocomplete from "@/components/Autocomplete";

const { v4: uuidV4 } = require('uuid');

export default {
  name: "AnnotateLabelGroups",
  components: {Autocomplete},
  props: {
    placeholder: {default: 'Add'},
    type: {default: 'box'}
  },
  mixins: [LoadAnnotationGroupsMixin],
  data () {
    return {
      class_name: false,
      options: [],
      label_meta: {},
      groups: [],
    }
  },
  computed: {
    group_options() {
      return this.groups.map(group => {
        return {'label': group.name, 'annotations': group.json_annotations};
      })
    }
  },
  methods: {
    preventDefault(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    'select' (item) {
      if(!item) {
        return;
      }
      
      this.$set(this, 'class_name', false);

      let annotations = JSON.parse(item.json_annotations);
      annotations.forEach(annotation=> {
        this.addAnnotation(annotation);
      })
    },
    addAnnotation (annotation) {
      annotation.id = uuidV4()
      this.$emit('add_annotation', annotation)
    }
  }
}
</script>

<style scoped>

</style>