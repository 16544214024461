<script>

export default {
  name: "LoadTrainConfigsAndModelsMixin",
  computed: {
  },
  methods: {
    async fetchModelsForTrainConfigIds() {
      let response_get_models = [];
      this.items.forEach(trainConfig => {
        if (trainConfig.relationships && trainConfig.relationships.models) {
          response_get_models = response_get_models.concat(trainConfig.relationships.models);
        }
      });
      
      this.$store.commit('model/set_items', response_get_models)
    }
  },
  mounted() {},
}
</script>

<style scoped>

</style>