<template>
  <div>
    <div>
        <loader :loading="loading"></loader>
        <run-model-modal v-if="related_model_in_modal" :model="related_model_in_modal" @close_modal="close_run_model_modal"></run-model-modal>     
        <primary-button :disabled="related_active_jobs.length > 0 || trainLimitReached || noValidationImages" @pressed="train()" :label="$t('models_and_train_configs.label_button_train')">
        </primary-button>
        <v-btn icon v-if="related_models.length>0" @click.native="show_run_model_modal(related_models)" color="primary" x-small>
          <v-icon>far fa-circle-play</v-icon>
        </v-btn>
        <v-tooltip v-if="noValidationImages" bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" color="orange" x-small>
              <v-icon>mdi-alert</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('models_and_train_configs.no_validation_images') }}</span>
        </v-tooltip>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/form/PrimaryButton";
import RunModelModal from "@/components/run_model_modal/RunModelModal";
import LoadProjectsMixin from "@/mixins/LoadProjectsMixin";
import Loader from "@/components/layout/loader";


export default {
  mixins: [LoadProjectsMixin],
  name: "TrainConfigTrainActionsCell",
  props: {
    train_config: Object, 
    models: Array,
    jobs: Array,
    trainLimitReached: {
      type: Boolean,
      default: false,
    },
  },
  components: {PrimaryButton, RunModelModal, Loader},
  data () {
    return {
        'loading': false,
        'related_model_in_modal': null,
        'noValidationImages': false,
    }
  },
  methods: {
    async checkIfNoValidationImagesForTrainConfig() {
      const response = await this.$api.asset.number_of_validation_images_for_train_config(this.train_config.id)
      if (response && response.number_of_validation_images === 0) {
        this.$set(this, 'noValidationImages', true);
      }
    },
    close_run_model_modal() {
      this.$set(this, 'related_model_in_modal', null)
    },
    async train() {
      this.$set(this, 'loading', true);
      let meta = JSON.parse(this.train_config.meta);
      if(!meta['mixup']) {
        alert('Train config outdated due to Box21 update, please edit and save again.');
        this.$set(this, 'loading', false);
        return;
      }
      let response = await this.$api.job.start_train_job(this.train_config.id);
      this.$emit('load_jobs_request');
      this.$set(this, 'loading', false);

      if (response && typeof response === 'object' && response.limit_reached && response.limit_type === 'TRAIN_LIMIT') {
        this.$emit("train-limit-reached");
      }
      if (response && typeof response === 'object' && response.error && response.error === 'No validation images found') {
        this.$set(this, 'noValidationImages', true);
      }
    },
    show_run_model_modal(related_models) {
      this.$set(this, 'related_model_in_modal', related_models[0])
    },
  },
  computed: {
    related_jobs() {
      return this.jobs.filter(job => {
        const meta = JSON.parse(job.description);
        return meta.train_config_id === this.train_config.id;
      });
    },
    related_active_jobs() {
      return this.jobs.filter(job => {
        const meta = JSON.parse(job.description);
        return job.processed != 1 && meta.train_config_id === this.train_config.id;
      });
    },
    related_models() {
      return this.models.filter(model => model.train_config_id === this.train_config.id);
    },
  },
  mounted() {
    this.checkIfNoValidationImagesForTrainConfig();
  },
}
</script>

<style scoped>

</style>