import Vue from 'vue'

export default {
    set_items (state, items) {
        Vue.set(state, 'items', items);
    },
    set_item (state, item) {
        let ids = state.items.map((i)=>{return i.id});
        let i = ids.indexOf(item.id)
        if(i===-1) {
            state.items.append(item);
        }
        else {
            Vue.set(state.items, i, item);
        }
    }
}