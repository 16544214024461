<template>
  <modal @close_modal="close_modal"
         :title="modalTitle"
         :loading="loading"
  >
    <template v-slot:tabs>
      <v-tabs>
        <v-tab @click="tab_selected='tab_model_type'">{{$t('set_train_c.edit_model_type')}}</v-tab>
        <v-tab @click="tab_selected='tab_label_sets'">{{$t('set_train_c.edit_labelsets')}}</v-tab>
        <v-tab @click="tab_selected='tab_advanced'">{{$t('set_train_c.edit_advanced')}}</v-tab>
      </v-tabs>
    </template>
    <template v-slot:content>
      <div v-if="related_models.length > 0">
        <v-alert type="warning" outlined dense>
          {{$t('models_and_train_configs.readonly_because_related_models')}}
        </v-alert>
      </div>
      
      <div v-if="tab_selected === 'tab_model_type'">
        <row>
          <col6>
            <b>{{$t('models_and_train_configs.choose_name_and_descriptions')}}</b><br><br>
            <v-text-field
                    v-model="name"
                    required
                    dense
                    outlined
            ></v-text-field>
            <v-textarea
              v-model="description"
              dense
              outlined
            ></v-textarea>
          </col6>
          <col6>
            <b>{{$t('models_and_train_configs.select_model_type')}}</b><br><br>
            <model-button-overview
                @clicked="select_model"
                :selected_model_id="selected_model_id"
                :model_buttons="all_available_model_buttons"
                :disabled="has_related_models"></model-button-overview>
          </col6>
        </row>
        <p v-if="selected_model_id !== false">
          <b>{{$t('models_and_train_configs.set_your_parameters')}}</b><br><br>
          <parameters
            @select="select"
            :all_parameters="available_model_parameter_for_selected_model"
            :selected_options="selected_options_for_selected_model_id"
            :disabled="has_related_models">
          </parameters>
          <show-alert v-if="selected_model_id!==false && selected_label_set_id==null" borderPosition="right" :alert-message="$t('models_and_train_configs.no_labelset_selected')"></show-alert>
        </p>
      </div>
      <div v-if="tab_selected === 'tab_label_sets'">
        <manage-label-sets
        :show_select_boxes="!has_related_models"
        @selected_label_set_id='selectedLabelSetId'
        :selected_label_set_id="selected_label_set_id"
        ></manage-label-sets>
      </div>

      <div v-if="tab_selected === 'tab_advanced'">
        <parameter name="Only annotations contained in:"
                   :default_value="false"
                   :value="crop_on_label"
                   @select="select_crop_on_label"
                   :options="label_names"
                   :disabled="has_related_models"></parameter>
        <row12>
          <parameters
              @select="select"
              :all_parameters="available_model_parameter_for_selected_model"
              :selected_options="selected_options_for_selected_model_id"
              :advanced="true"
              v-if="selected_model_id !== false"
              :disabled="has_related_models"
              ></parameters>
        </row12>
      </div>

  
    </template>

    
    <template v-slot:buttons>
      <v-btn
          color="primary"
          text
          id="cancel_button"
          @click.native="cancel"
      >
        {{$t('set_train_c.edit_cancel')}}
      </v-btn>

      <v-btn
          :disabled="!selected_model_id || !selected_label_set_id"
          color="primary"
          text
          id="save_button"
          @click.native="save"
      >
        {{$t('set_train_c.edit_save')}}
        <v-icon
            dark
            primary
            right
        >
          fas fa-level-down-alt fa-rotate-90
        </v-icon>
      </v-btn>
    </template>

  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import Col6 from "@/components/layout/Col6";
import Row from "@/components/layout/Row";
import Parameters from "@/components/train_configs/Parameters";
import Parameter from "@/components/train_configs/Parameter";
import ModelButtonOverview from "@/components/train_configs/ModelButtonOverview.vue";
import Row12 from "@/components/layout/Row12.vue";
import LoadLabelsMixin from "@/mixins/LoadLabelsMixin.vue";
import LoadAvailableModelsMixin from "@/mixins/LoadAvailableModelsMixin.vue";
import ManageLabelSets from "@/components/ManageLabelSets.vue";
import ShowAlert from "@/components/ShowAlert.vue";

export default {
  name: "EditTrainConfiguration",
  components: {Row12, ModelButtonOverview, Parameter, Parameters, Row, Col6, Modal, ManageLabelSets, ShowAlert},
  mixins: [LoadLabelsMixin, LoadAvailableModelsMixin],
  props: {
    train_config: {required: true},
    models: Array,
    is_new_train_conf: {type: Boolean, default: false},
  },
  data() {
    return {
      selected_options: {},
      selected_model_id: false,
      selected_model_type: false,
      show_advanced_options: true,
      name: '',
      description: '',
      tab_selected: 'tab_model_type',
      loading: true,
      label_ids: [],
      model_type: '',
      image_size: 0,
      crop_on_label: false,
      selected_label_ids: [],
      selected_label_set_id : null,
    }
  },
  watch: {
    available_models_loaded() {
      this.load_current_config();
    }
  },
  mounted() {
    if(!this.available_models_loaded) {
      this.load_current_config();
    }
    
    this.$set(this, 'name', this.train_config.name)
    this.$set(this, 'description', this.train_config.description)
    this.$set(this, 'loading', false);
  },
  methods: {
    select_model(selected_model_button) {
      this.on_click(selected_model_button.id, selected_model_button.type);
    },
    select_label_ids(label_ids) {
      this.$set(this, 'selected_label_ids', label_ids);
    },
    select(value) {
      let selected_options = this.selected_options;
      if (!selected_options[this.selected_model_id]) {
        this.$set(this.selected_options, this.selected_model_id, {});
      }
      this.$set(this.selected_options[this.selected_model_id], value.parameter_id, value.value);
    },
    select_crop_on_label(value) {
      this.$set(this, 'crop_on_label', value);
    },
    on_click(model_id, model_type) {
      this.$set(this, 'selected_model_id', model_id);
      this.$set(this, 'selected_model_type', model_type);

      if(!Object.keys(this.selected_options).includes(this.selected_model_id)) {
        this.$set(this.selected_options, this.selected_model_id, {});
      }

      for(let i=0; i<this.available_model_parameter_for_selected_model.length; i++) {
        let param = this.available_model_parameter_for_selected_model[i]

        if(param.default_value && !Object.keys(this.selected_options[this.selected_model_id]).includes(param.id)) {
          this.$set(this.selected_options[this.selected_model_id], param.id, param.default_value.toString());
        }
      }
    },
    async save() {
      let type = this.available_models.filter(model => model.id === this.selected_model_id)[0].name;
      let meta = this.selected_options_for_selected_model_id;
      meta['selected_model_id'] = this.selected_model_id
      meta['type'] = type;
      meta['crop_on_label'] = this.crop_on_label
      if (this.selected_label_set_id === null) {
        alert('Could not save because there is no label set');
        return;
      }
      meta['label_set_id'] = this.selected_label_set_id

      meta['model_type'] = this.selected_model_type
      let train_config = {
        id: this.train_config.id,
        name: this.name,
        repeat_after_days: null,
        description: this.description,
        meta: JSON.stringify(meta)
      }

      if(train_config.id) {
          await this.$store.dispatch('train_config/patch', { item: train_config, dispatch: this.$store.dispatch });
      }
      else {
          await this.$store.dispatch('train_config/add', train_config);
      }

      this.$emit('reload')
      this.$emit('close_modal')
    },
    cancel () {
      this.$emit('close_modal')
    },
    toggle_label(label_id) {
      let label_ids = this.label_ids
      if(label_ids.includes(label_id)) {
        label_ids = label_ids.filter((id)=> {return id !== label_id});
      }
      else {
        label_ids.push(label_id)
      }
      this.$set(this, 'label_ids', label_ids)
    },
    close_modal() {
      this.$emit('close_modal')
    },
    async load_current_config() {

      let meta = JSON.parse(this.train_config.meta)

      let crop_on_label = meta.crop_on_label
      this.$set(this, 'crop_on_label', crop_on_label)

      let selected_label_set_id = meta.label_set_id
      this.$set(this, 'selected_label_set_id', selected_label_set_id)

      let selected_model_id = meta.selected_model_id

      if(selected_model_id) {
        this.on_click(selected_model_id,  meta.model_type)
        this.$set(this, 'selected_model_type', meta.model_type)
      }

      if(!meta.selected_model_id) {
        //OLD STYLE
        return;
      }

      let parameter_keys = Object.keys(meta);

      for(let i=0; i<parameter_keys.length; i++) {
        let parameter_key = parameter_keys[i]
        if(meta[parameter_key] !== 'selected_model_id') {
          this.$set(this.selected_options[this.selected_model_id], parameter_key, meta[parameter_key])
        }
      }

      this.$set(this, 'loading', false);
    },
    selectedLabelSetId(selected_label_set_id) {
      this.$set(this, 'selected_label_set_id', selected_label_set_id)
      },
  },
  computed: {
    modalTitle() {
      return this.is_new_train_conf ? this.$t('set_train_c.add_title') : this.$t('set_train_c.edit_title');
    },
    has_related_models() {
      return this.related_models.length > 0;
    },
    related_models() {
      if(!this.models) {
        return [];
      }
      return this.models.filter(model => model.train_config_id === this.train_config.id);
    },
    available_model_parameter_for_selected_model() {
      let parameters = this.parameters_for_all;

      let model_name = this.available_models.filter(model => model.id === this.selected_model_id)[0].name;

      let available_model_parameters = this.parameters_per_available_model[model_name]
      if(available_model_parameters) {
          parameters = [...parameters, ...available_model_parameters]
      }
      let model_type_parameters = this.parameters_per_model_type[this.selected_model_type]
        if(model_type_parameters) {
            parameters = [...parameters, ...model_type_parameters]
        }
      return parameters;
    },
    all_available_model_buttons() {
      let buttons = []
      for(let i=0; i<this.available_models.length; i++) {
        let model_type = this.available_models[i].id
        let model_button = this.available_models[i];
        model_button['model_type'] = model_type
        buttons.push(model_button)
      }
      return buttons
    },
    selected_options_for_selected_model_id() {
      if(!this.selected_model_id) {
        return []
      }

      if(this.selected_options[this.selected_model_id]) {
        return this.selected_options[this.selected_model_id]
      }

      return []
    },
    selected_label () {
      let label_ids = this.labels.map(i=>{return i.id})
      let labels = this.label_ids.map(id=>{
        let i = label_ids.indexOf(id)
        if (i>=0) {
          return this.labels[i].name
        }
        else {
          return 'Deleted label'
        }
      })
      return labels.join(', ')
    },
  },
}
</script>

<style scoped>

</style>