<template>
  <row12>
    <v-overlay v-if="loading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <row v-if="show_select">
      <col12 style="text-align:right">
        <h4>{{$t('set_label_sets.selected_labels')}}</h4>
        <v-chip v-for="label in selected_labels" :key="label.id" close @click:close="() => unselect_label(label.id)"
                :dark="true" small
                :style="{ 'font-weight': 'bold', 'background-color': `rgba(${label.color})` }">
          {{ label.name }}
        </v-chip>
      </col12>
    </row>

    <div>
      <v-btn small color="primary" @click.native="viewmodus='tree'">
        <v-icon x-small>
          fas fa-sitemap
        </v-icon>
        <span style="padding-left: 10px;">{{$t('set_labels.tree_view')}}</span>
      </v-btn>
      <v-btn small color="primary" @click.native="viewmodus='list'">
        <v-icon x-small>
          fas fa-th-list
        </v-icon>
        <span style="padding-left: 10px;">{{$t('set_labels.list_view')}}</span>
      </v-btn>
    </div>
    <my-boxes-modal :label_name="boxes_modal_label_name" v-if="show_boxes_modal" @close_modal="close_preview_label_in_modal"></my-boxes-modal>
    <list-view v-if="viewmodus==='list'" :show_select="show_select" :labels="labels"
               :selected_labels_ids="selected_labels_ids"
               @select_label_ids="select_label_ids"
               @update_label="update_label"
               @preview_label="preview_label_in_modal"
                ></list-view>
    <tree-view v-if="viewmodus==='tree'"
               :show_select="show_select" :labels="labels"
               :selected_labels_ids="selected_labels_ids"
               @select_label_ids="select_label_ids"
               @preview_label="preview_label_in_modal"
               ></tree-view>
  </row12>
</template>

<script>
import Row12 from "@/components/layout/Row12";
import ListView from "@/components/manage_labels/ListView";
import TreeView from "@/components/manage_labels/TreeView";
import LoadLabelsMixin from "@/mixins/LoadLabelsMixin";
import MyBoxesModal from "@/components/MyBoxesModal";
import Row from "@/components/layout/Row.vue";
import Col12 from "@/components/layout/Col12.vue";


export default {
  name: "LabelList",
  data() {
    return {
      'viewmodus': 'list', //or list
      'loading' : true,
      'show_boxes_modal': false,
      'boxes_modal_label_name': false
    }
  },
  props: {
    selected_labels_ids: {default: () => []},
    show_select: {default: false},
  },
  watch: {
    labels_loaded(n) {
      if(n) {
        this.$set(this, 'loading', false)
      }
    }
  },
  mixins: [LoadLabelsMixin],
  methods: {
    select_label_ids (ids) {
      this.$emit('select_label_ids', ids)
    },
    unselect_label(label_id) {
      let updated_selected_label_ids = this.selected_labels_ids.filter(id => id != label_id);
      this.$emit('select_label_ids', updated_selected_label_ids)
    },
    preview_label_in_modal(label_name) {
      this.$set(this, 'show_boxes_modal', true);
      this.$set(this, 'boxes_modal_label_name', label_name);
    },
    close_preview_label_in_modal() {
      this.$set(this, 'show_boxes_modal', false);
      this.$set(this, 'boxes_modal_label_name', null);
    },
    update_label(label) {
      this.$store.dispatch('label/patch', { item: label, dispatch: this.$store.dispatch })
    }
  },
  computed: {
    selected_labels() {
      return this.labels.filter(label => this.selected_labels_ids.includes(label.id))
    },
  },
  mounted () {
  },
  components: {Col12, Row, MyBoxesModal, TreeView, ListView, Row12},
}
</script>

<style scoped>

</style>