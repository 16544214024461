<template>
  <div>
    <row>
      <col12>
        <v-card elevation="2">
          <v-card-text>
            <div class="icon-title">
              <v-icon>mdi-magic-staff</v-icon>
              <b> {{$t('myprofile.seg_any_settings')}}</b>
            </div>
            <div>
              <v-checkbox
                v-model="setting_enable_seg_any"
                :label="$t('myprofile.enable_seg_any')"
                @change="toggleEnableSegAnySetting"
              ></v-checkbox>
            </div>
          </v-card-text>
        </v-card>
      </col12>
    </row>
  </div>
    
</template>

<script>

import Row from "@/components/layout/Row";
import Col12 from "@/components/layout/Col12";


export default {
  name: "SegAnySettings.vue",
  components: {Row, Col12},
  mixins: [],
  data() {
    return {
      setting_enable_seg_any: null,
    }
  },
  mounted() {
    this.set_setting();
  },
  methods: {
    async set_setting() {
      this.all_user_settings = await this.$api.user_setting.all();
      console.log('this.all_user_settings', this.all_user_settings)
      let enable_seg_any_setting_list = this.all_user_settings.items.filter(
        (user_setting) => {
          return user_setting.key === "SettingEnableSegAny";
        }
      );
      if (enable_seg_any_setting_list.length !== 0) {
        this.$set(
          this,
          "setting_enable_seg_any",
          enable_seg_any_setting_list[0].value === "true"
        );
      } else {
        this.$set(this, "setting_enable_seg_any", false);
      }
    },
    async toggleEnableSegAnySetting() {
      await this.$api.user_setting.update_user_setting(
        "SettingEnableSegAny",
        this.setting_enable_seg_any
      );
    },
  }
}
</script>

<style scoped>

</style>