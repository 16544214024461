<script>
import {eyeTypes} from "@/components/users/assetsTypes/eyes"
import {clothesType} from "@/components/users/assetsTypes/clothes"
import {hairColors, hatAndShirtColors, skinColors} from "@/components/users/assetsTypes/colors";
import {eyebrowTypes} from "@/components/users/assetsTypes/eyebrows";
import {facialHairTypes} from "@/components/users/assetsTypes/facial-hair";
import {mouthTypes} from "@/components/users/assetsTypes/mouth";
import {accessoriesTypes} from "@/components/users/assetsTypes/accessories";
import {topTypes} from "@/components/users/assetsTypes/top";
import {GraphicShirtTypes} from "@/components/users/assetsTypes/graphic-shirt";

export default {
  data() {
    return {
      'gravatar_initialized': false,
      'properties': {
        skin_color: {
          'value': null,
          'options': Object.keys(skinColors)
        },
        top: {
          'value': null,
          'options': Object.keys(topTypes)
        },
        top_color: {
          'value': null,
          'options': Object.keys(hatAndShirtColors)
        },
        hair: {
          'value': null,
          'options': Object.keys(facialHairTypes)
        },
        hair_color: {
          'value': null,
          'options': Object.keys(hairColors)
        },
        accessoire: {
          'value': null,
          'options': Object.keys(accessoriesTypes)
        },
        clothes: {
          'value': null,
          'options': Object.keys(clothesType)
        },
        graphic_type: {
          'value': null,
          'options': Object.keys(GraphicShirtTypes)
        },
        eye_brown: {
          'value': null,
          'options': Object.keys(eyebrowTypes)
        },
        eyes: {
          'value': null,
          'options': Object.keys(eyeTypes)
        },
        clothes_color: {
          'value': null,
          'options': Object.keys(hatAndShirtColors)
        },
        mouth: {
          'value': null,
          'options': Object.keys(mouthTypes)
        },
      },
    }
  },
  computed: {
    properties_encoded() {
      let property_types = Object.keys(this.properties)
      let properties = {}
      for (let i = 0; i < property_types.length; i++) {
        let property_type = property_types[i];
        properties[property_type] = this.properties[property_type].value
      }
      return properties
    },
    property_types() {
      return Object.keys(this.properties)
    }
  },
  name: "GravatarChooser",
  watch: {
    skin_color_types() {
      console.log(this.skin_color_types);
    }
  },
  mounted() {
    this.$set(this, 'gravatar_initialized', true)
  },
  methods: {
    get_random_value(property) {
      return this.properties[property].options[0]
    },
    update_prop(type, value) {
      this.$set(this.properties[type], 'value', value)
    },
    typeToDescription(type) {
      let desc = type.replace('_', '')
      let pretty_description = desc.charAt(0).toUpperCase() + desc.slice(1)

      if(pretty_description === 'Hair') {
        return 'Facial hair'
      }
      return pretty_description;
    }
  }
}
</script>

<style scoped>

</style>