<template>
  <div>
    <div>
      <edit-train-configuration
        @close_modal="hide_train_config_modal"
        :train_config="train_config_in_modal"
        v-if="train_config_in_modal"
        :models="models"></edit-train-configuration>
    <treshold-slider @threshold-changed="handle_threshold_change"></treshold-slider>
    <div v-if="showTrainLimitReachedAlert">
      <show-alert :alert-message="$t(trainLimitReachedMessage)"></show-alert>
    </div>
    <v-text-field
          v-model="filter"
          append-icon="mdi-magnify"
          :label="$t('models_and_train_configs.search')"
          single-line
          hide-details
      ></v-text-field>
    <v-data-table
          :items="items"
          :headers="headers"
          :options.sync="options"
          :server-items-length="totalItems"
        >
        <template v-slot:item.name="{ item }" >
            <train-config-name-cell
            :train_config="item" 
            :jobs="jobs"
            :models="models"
            @show_train_config_modal="show_train_config_modal"
            @load_items="reload"
            ></train-config-name-cell>
          </template>
        <template v-slot:item.jobs="{ item }" >
            <train-config-training-state-cell
            :train_config="item" 
            :jobs="jobs"
            ></train-config-training-state-cell>
          </template>
        <template v-slot:item.models="{ item }" >
            <train-config-models-and-performance-cell
            :train_config="item"
            :jobs="jobs"
            :models="models"
            :threshold="threshold"
            ></train-config-models-and-performance-cell>
          </template>
        <template v-slot:item.updated_at="{ item }" >
            <train-config-last-updated-cell
            :train_config="item"
            ></train-config-last-updated-cell>
          </template>
        <template v-slot:item.repeat_after_days="{ item }" >
            <train-config-repeat-after-days-cell
            :train_config="item"
            ></train-config-repeat-after-days-cell>
          </template>
        <template v-slot:item.actions="{ item }">
          <train-config-actions-cell 
          :train_config="item" 
          :models="models" 
          :jobs="jobs"
          @load_jobs_request="load_jobs"
          @train-limit-reached="handleTrainLimitReached"
          :trainLimitReached="showTrainLimitReachedAlert"
          ></train-config-actions-cell>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>

import LoadTrainConfigsMixin from "@/mixins/LoadTrainConfigsMixin";
import LoadTrainConfigsAndModelsMixinVue from '@/mixins/LoadTrainConfigsAndModelsMixin.vue';
import ApiDatatableMixin from "@/mixins/ApiDatatableMixin";
import EditTrainConfiguration from "@/components/EditTrainConfiguration";
import TresholdSlider from "@/components/train_configs_and_models/TresholdSlider.vue";
import ShowAlert from "@/components/ShowAlert.vue";
import CurrentUserMixin from "@/mixins/CurrentUserMixin";

import TrainConfigNameCell from '@/components/train_configs_and_models/TrainConfigNameCell.vue';
import TrainConfigTrainingStateCell from '@/components/train_configs_and_models/TrainConfigTrainingStateCell.vue';
import TrainConfigModelsAndPerformanceCell from '@/components/train_configs_and_models/TrainConfigModelsAndPerformanceCell.vue';
import TrainConfigLastUpdatedCell from '@/components/train_configs_and_models/TrainConfigLastUpdatedCell.vue';
import TrainConfigRepeatAfterDaysCell from '@/components/train_configs_and_models/TrainConfigRepeatAfterDaysCell.vue';
import TrainConfigActionsCell from '@/components/train_configs_and_models/TrainConfigActionsCell.vue';


export default {
  name: "ModelsAndTrainConfigs2",
  mixins: [LoadTrainConfigsMixin, LoadTrainConfigsAndModelsMixinVue, ApiDatatableMixin, CurrentUserMixin],
  components: {EditTrainConfiguration, TrainConfigActionsCell, TrainConfigNameCell, TrainConfigTrainingStateCell, TrainConfigModelsAndPerformanceCell, 
              TrainConfigLastUpdatedCell, TrainConfigRepeatAfterDaysCell, TresholdSlider, ShowAlert},
  data () {
    return {
      'options': {sortBy: ['updated_at'], sortDesc: [true]},
      'train_config_in_modal': null,
      'api_model': 'train_config',
      'edit_mode': false,
      'item' : "",
      'jobs': [],
      'threshold': 60,
      'showTrainLimitReachedAlert': false,
    }
  },
  watch: {
    trainConfigIds: {
      handler(newTrainConfigIds) {
        if (newTrainConfigIds.length > 0) {
          this.fetchModelsForTrainConfigIds();
        }
      },
    },
  },
  mounted() {
    this.load_jobs()
  },
  methods: {
    handleTrainLimitReached() {
      this.$set(this, 'showTrainLimitReachedAlert', true)
    },
    show_train_config_modal(train_config) {
      if(!train_config) {
        return
      }
      this.$set(this, 'train_config_in_modal', train_config)
    },
    hide_train_config_modal() {
      this.loadItems()
      this.$set(this, 'train_config_in_modal', null)
    },
    async load_jobs() {
      let jobs = await this.$api.job.get_active_jobs('train')
      if(jobs) {
        this.$set(this, 'jobs', jobs)
        let this2 = this;
        if(!this.destroyed) {
          this.timeout = setTimeout(() => {
            this2.load_jobs()
            this2.fetchModelsForTrainConfigIds()
          }, 5000)
        }
      }
    },
    async reload() {
      await this.loadItems();
    },
    handle_threshold_change(newThreshold) {
      this.threshold = newThreshold;
    },
  },
  computed: {
    trainLimitReachedMessage() {
      return this.getLimitMessage('models_and_train_configs', 'TRAIN_LIMIT');
    },
    models () {
      return this.$store.getters['model/get_items']
    },
    trainConfigIds() {
      return this.train_configs.map(trainConfig => trainConfig.id);
    },
    headers () {
      return [
        {text: this.$t('models_and_train_configs.name'), value: 'name', sortable: true},
        {text: this.$t('models_and_train_configs.training_state'), value: 'jobs', sortable: false},
        {text: this.$t('models_and_train_configs.models_and_performance'), value: 'models', sortable: false},
        {text: this.$t('models_and_train_configs.last_updated'), value: 'updated_at', sortable: true},
        {text: this.$t('models_and_train_configs.repeat_after_days'), value: 'repeat_after_days', sortable: true},
        {text: this.$t('models_and_train_configs.actions'), value: 'actions', sortable: false },
      ]
    },
  }
}
</script>

<style scoped>

</style>