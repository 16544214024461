<script>

export default {
  name: "MyAssetActionsMixin",
  methods: {
    async validate_all() {
      this.loading = true;
      await this.$api.asset.mark_validated(this.asset_ids_selected);
      this.load();
    },
    async add_to_validation_set() {
      this.loading = true;
      await this.$api.asset.add_to_validation_set(this.asset_ids_selected);
      this.load();
    },
    async remove_from_validation_set() {
      this.loading = true;
      await this.$api.asset.remove_from_validation_set(this.asset_ids_selected);
      this.load();
    },
    async invalidate_all() {
      this.loading = true;
      await this.$api.asset.mark_invalidated(this.asset_ids_selected);
      this.load();
    },
    async close_run_model_modal() {
      this.$set(this, 'run_model_modal', false);
    },
    async run_model() {
      this.$set(this, 'run_model_modal', true);
    },
    async delete_assets() {
      this.loading = true;
      await this.$api.asset.delete(this.asset_ids_selected);
      this.load();
    },
  }
}
</script>

<style scoped>

</style>