export default class Label {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async all(filter) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'))
        if(typeof filter != 'undefined') {
            if(filter.filter_name) {
                formData.append('filter_name', filter.filter_name);
            }
            if(filter.filter_type) {
                formData.append('filter_type', filter.filter_type);
            }
        }
        return this.api.post_with_token('/labels', formData);
    }

    async patch(label) {
        let formData = new FormData();
        formData.append('id', label.id)
        formData.append('name', label.name)
        formData.append('color', label.color)
        formData.append('type', label.type)
        if(label.parent_id) {
            formData.append('parent_id', label.parent_id)
        }
        return this.api.post_with_token('/update-label', formData);
    }

    async delete(label) {
        let formData = new FormData();
        formData.append('label_id', label.id);
        formData.append('project_id', sessionStorage.getItem('project_id'))
        return this.api.post_with_token('/label/delete', formData)
    }


    async add(label) {
        let formData = new FormData();
        formData.append('name', label.name);
        formData.append('type', label.type);
        formData.append('color', label.color);
        formData.append('project_id', sessionStorage.getItem('project_id'))
        return this.api.post_with_token('/label/add', formData)
    }
}