<template>
  <div>
    <row>
      <col5>
        <v-text-field dense outlined id="label_input" :label="$t('set_labels.label')" style="float: left" v-model="item_name"></v-text-field>
      </col5>
      <col4>
        <v-btn id="add_new_label_button" color="primary" @click.native="add_item">{{$t('set_labels.btn_add')}}</v-btn>
      </col4>
    </row>
    <v-divider></v-divider>
    <label-list id="label_list_component"></label-list>
  </div>
</template>

<script>
import Row from "@/components/layout/Row";
import Col5 from "@/components/layout/Col5";
import LabelList from "@/components/manage_labels/LabelList";
import Label from "@/models/label";
import Col4 from "@/components/layout/Col4";
export default {
  name: "ManageLabels",
  components: {Col4, LabelList, Col5, Row},
  data() {
    return {
      'item_name': '',
    }
  },
  props: {
  },
  methods: {
    async add_item() {
      if(this.item_name === '') {
        return;
      }
      let label = new Label(false, this.item_name.trim(), 1, '0,99,133', false)
      await this.$store.dispatch('label/add', label)
      await this.$store.dispatch('snackbars/add_success_snack', {
        'message': this.$t('snacks.label_added'),
      })
    },
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>